export default {
  items: [
    {
      name: 'Configurações',
      url: '/configuracao',
      icon: 'fa fa-cogs',
      children: [
        {
          name: 'Configurações Gerais',
          url: '/configuracao/geral',
          icon: 'fa fa-cog'
        },
        {
          name: 'Grupo de usuários',
          url: '/configuracao/grupo_permissao',
          icon: 'fa fa-users'
        },
        {
          name: 'Cadastro de Usuários',
          url: '/configuracao/usuario',
          icon: 'fa fa-user'
        },
        /* {
          name: 'Alterar Senha',
          url: '/configuracao/senha/alterar',
          icon: 'fa fa-user-circle'
        } */
        {
          name: 'Dados da revenda',
          url: '/configuracao/dados_revenda',
          icon: 'fa fa-address-card'
        },
        {
          name: 'Lista de crons',
          url: '/configuracao/cron',
          icon: 'icon-list'
        }
      ]
    },
    {
      name: 'Vendas',
      url: '/vendas',
      icon: 'fa fa-dollar',
      children: [
        {
          name: 'Meus Clientes',
          url: '/vendas/clientes',
          icon: 'fa fa-users'
        },
        {
          name: 'Produtos',
          url: '/vendas/produtos',
          icon: 'fa fa-shopping-cart'
        },
        {
          name: 'Orçamentos',
          url: '/vendas/orcamentos',
          icon: 'fa fa-sticky-note-o'
        },
        {
          name: 'Pedidos de Venda',
          url: '/vendas/pedidos',
          icon: 'fa fa-sticky-note'
        }
      ]
    },
    {
      name: 'Financeiro',
      url: '/financeiro',
      icon: 'fa fa-line-chart',
      children: [
        {
          name: '2ª Via de Boleto',
          url: '/financeiro/segunda_via',
          icon: 'fa fa-barcode'
        },
        {
          name: 'Premiações',
          url: '/financeiro/premiacoes',
          icon: 'fa fa-money'
        }
      ]
    },
    {
      name: 'Atendimento',
      url: '/atendimento',
      icon: 'fa fa-phone'
    },
    {
      name: 'Sair',
      url: '/logout',
      icon: 'fa fa-power-off red'
    }
  ]
}
