<template>
  <div>
    <loading :active.sync="isLoading"></loading>Aside
  </div>
</template>

<script>
export default {
  name: "DefaultAside",
  computed: {
    isLoading: function() {
      if (
        this != null &&
        this.$store != null &&
        this.$store.state != null &&
        this.$store.state.ui != null &&
        this.$store.state.ui.loading != null
      )
        return this.$store.state.ui.loading;
      return false;
    }
  }
};
</script>
